import { StatusCodes } from "http-status-codes";

export default defineNuxtRouteMiddleware(() => {
  const {
    public: { appEnv },
  } = useRuntimeConfig();

  const { generalErrorMessage } = useCommonErrorMessage();

  if ((appEnv as string) === "prod") {
    throw createError({
      statusCode: StatusCodes.NOT_FOUND,
      message: generalErrorMessage.value,
    });
  }
});
